
// import { breakpoints } from '../variables.yml';

// import './vendor/lib.js';
import { LightBox } from './vendor/LightBox.js';
// import { getWindowWidth } from './helpers.js';

( () => {
	// Cookie notice
	if ( document.cookie.indexOf( 'seenCookieNotice' ) === -1 ) {
		document.cookie = 'seenCookieNotice=yes;path=/;max-age=31536000;secure';
		document.body.classList.add( 'show-cookie-notice' );
		document.querySelector( '.cookie-notice button' ).addEventListener( 'click', () => {
			document.body.classList.remove( 'show-cookie-notice' );
		} );
	}
} )();

( () => {
	function doToggle( toggler ) {
		const togglees = document.querySelectorAll( toggler.getAttribute( 'data-togglees' ) );
		for ( let i = 0; i < togglees.length; i += 1 ) {
			togglees[i].classList.toggle( 'toggled', !togglees[i].classList.contains( 'toggled' ) );
		}
		toggler.classList.toggle( 'toggler--toggled', !toggler.classList.contains( 'toggler--toggled' ) );
	}

	window.addEventListener( 'click', ( event ) => {
		if ( !event.target.classList.contains( 'toggler' ) ) {
			return;
		}
		doToggle( event.target );
	} );
} )();

( () => {
	// Print this page button
	const print_button = document.getElementById( 'js-print' );
	if ( !print_button ) {
		return;
	}

	print_button.addEventListener( 'click', () => {
		window.print();
	} );
} )();

( () => {
	new LightBox();
} )();
